import {STATE_AUTHENTICATED, STATE_IDENTIFIED, STATE_UNAUTHENTICATED} from '../constants/const';

export const selectName = (state) => state.app.name;

export const selectAccessToken = (state) =>
    state.app.oauth.token
    && state.app.oauth.token['access_token'];
export const selectFlowId = (state) => state.app.oauth.flowId;

export const selectHost = (state) => state.app.oauth.domain;

export const selectProjectId = (state) => state.app.oauth.projectId;

export const selectNetworkIsConnected = (state) => state.app.network.isConnected;

export const selectIsAuthenticated = (state) =>
    state.app.state === STATE_AUTHENTICATED
    || state.app.state === STATE_IDENTIFIED;

export const selectIsNotAuthenticated = (state) => state.app.state === STATE_UNAUTHENTICATED;

export const selectIsIdentified = (state) => state.app.state === STATE_IDENTIFIED;

export const selectLastSyncedAt = (state) => state.app.lastSyncedAt;

export const selectAuthenticationError = (state) => state.app.authentication.error;

export const selectAuthenticationIsProcessing = (state) => state.app.authentication.isProcessing;

export const selectPage = (state) => state.app.page;