import {combineReducers} from 'redux';
import appReducer from './reducers/appReducer';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {persistStore, persistReducer} from 'redux-persist'
import scanDesksReducer from './reducers/scanDesksReducer';
import createSagaMiddleware from 'redux-saga';
import scanReducer from './reducers/scanReducer';
import rootSaga from './sagas/rootSagas';
import reportReducer from './reducers/reportReducer';
import {configureStore} from '@reduxjs/toolkit';

const persistConfig = {
    key: 'root',
    storage: AsyncStorage,
}

const rootReducer = combineReducers(
    {
        app: appReducer,
        report: reportReducer,
        scanDesks: scanDesksReducer,
        scans: scanReducer,
    }
)

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: persistedReducer,
    middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export {
    store,
    persistor,
};