import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {networkFrequency, syncFrequency} from '../config/config';
import {networkSetConnected, networkSetDisconnected, sync} from '../actions/index';
import {selectNetworkIsConnected} from "../selectors/appSelectors";

const Sync = ({children}) => {
    const dispatch = useDispatch();
    const isConnected = useSelector(selectNetworkIsConnected);

    useEffect(() => {
        setInterval(
            () => {
                dispatch(sync());
            },
            syncFrequency
        );
    }, [dispatch]);

    useEffect(() => {
        setInterval(
            () => {
                const onLine = window.navigator.onLine;
                if (isConnected !== onLine) {
                    dispatch(onLine ? networkSetConnected() : networkSetDisconnected());
                }
            },
            networkFrequency
        )
    }, [isConnected, dispatch])

    return <>{children}</>;
}

export default Sync;