export class Api {
    static Authenticate = (tokenEndpointUrl, clientId, clientSecret) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `grant_type=client_credentials&client_id=${clientId}&client_secret=${clientSecret}`,
        };
        return fetch(tokenEndpointUrl, requestOptions);
    }

    static GetScanDesks = (
        domain,
        token,
        projectId,
        flowId
    ) => {
        const endPoint = `${domain}/api/integration/ticketing/flow/${flowId}/scandesks`;
        const options = {
            'method': 'GET',
            'headers': {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'project-id': projectId,
                'Host': domain.replace(/^https?:\/\//, ''),
                'User-Agent': 'Halito! Scan-App',
            }
        }

        return fetch(endPoint, options);
    }

    static Scan = (
        domain,
        token,
        projectId,
        flowId,
        formId,
        scanDeskId,
        barcode,
    ) => {
        const endPoint = `${domain}/api/integration/ticketing/flow/${flowId}/forms/${formId}/scandesks/${scanDeskId}/scan`;
        const options = {
            'method': 'POST',
            'headers': {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'project-id': projectId,
                'User-Agent': 'Halito! Scan-App',
            },
            'body': JSON.stringify({
                barcode: barcode,
            })
        }
        return fetch(endPoint, options);
    }

    static Sync = (host, token, projectId, barcodes, since) => {
        const endPoint = `${host}/api/registrations/scanning/sync`;
        const options = {
            'method': 'POST',
            'headers': this.getHeaders(host, token, projectId),
            'body': JSON.stringify({
                since,
                scans: barcodes,
            })
        };

        return fetch(endPoint, options);
    }


    static Stats = (host, token, projectId, flowId, formId) => {
        const endPoint = `${host}/api/integration/ticketing/flow/${flowId}/forms/${formId}/scandesks/reports/global`;
        const options = {
            'method': 'GET',
            'headers': this.getHeaders(host, token, projectId),
        };

        return fetch(endPoint, options);
    }

    static getHeaders = (host, accessToken, projectId) => {
        return {
            'Authorization': `Bearer ${accessToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'project-id': projectId,
            'Host': host.replace(/^https?:\/\//, ''),
            'User-Agent': 'Halito! Scan-App',
        }
    }
}