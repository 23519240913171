import React from 'react';
import PropTypes from 'prop-types';
import {Button} from "react-md";

const ScanDeskConfirm = ({name, onConfirm, onDismiss}) => {
    return <div className="mt-4 flex flex-col gap-5">
        <p>You selected position:</p>
        <p className="font-bold text-center text-primary text-lg">{name}</p>
        <div className="w-full flex gap-2 justify-around">
            <Button onClick={onConfirm} theme="primary" themeType="contained">
                Start scanning
            </Button>
            <Button onClick={onDismiss}  theme="primary" themeType="outline">
                Cancel
            </Button>
        </div>
    </div>
}

ScanDeskConfirm.propTypes = {
    name: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onDismiss: PropTypes.func.isRequired,
};

export default ScanDeskConfirm;
