import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectName, selectNetworkIsConnected} from '../selectors/appSelectors';
import {selectScanDesks, selectScanDesksIsLoading} from '../selectors/scanDeskSelectors';
import {scanDeskSelected, scanDesksFetch} from '../actions/index';
import Loading from "./Loading";
import ConnectionRequired from "./ConnectionRequired";
import ScanDeskConfirm from "./ScanDeskConfirm";
import {List, ListItem} from "react-md";

const ScanDesks = () => {
    const dispatch = useDispatch();
    const [selected, setSelected] = useState(null);
    const name = useSelector(selectName);
    const isConnected = useSelector(selectNetworkIsConnected);
    const isLoading = useSelector(selectScanDesksIsLoading);
    const scanDesks = useSelector(selectScanDesks);

    // fetch scanDesks when connectivity is restored
    useEffect(() => {
        isConnected && dispatch(scanDesksFetch());
    }, [isConnected, dispatch]);

    const handleConfirm = () => {
        dispatch(scanDeskSelected(selected));
        setSelected(null);
    }

    const handleDismiss = () => setSelected(null);

    const registrationPoint = () => {
        return scanDesks.find((item) => item.id === selected).name;
    }

    let scanDeskButtons = scanDesks.map((scanDesk) => {
        return <ListItem id={scanDesk.id}
                         key={'scandesk_button_' + scanDesk.id}
                         className="bg-white"
                         onClick={() => setSelected(scanDesk.id)}>
            {scanDesk.name}
        </ListItem>
    })

    if (isLoading)
        return <Loading/>;

    if (!isConnected && scanDesks.length === 0)
        return <ConnectionRequired message={'Halito! ScanApp requires internet connectivity to initially load scan desks'}/>

    if (selected) {
        return <ScanDeskConfirm
            name={registrationPoint()}
            onConfirm={handleConfirm}
            onDismiss={handleDismiss}
        />
    }
    return <div className="mt-4 flex flex-col gap-4 text-center">
        <p className="text-primary font-bold text-xl mb-4">{`Hi ${name}!`}</p>
        <p>Please select your position</p>
        <div>
            <List>
                {scanDesks && scanDesks.length > 0 && scanDeskButtons}
                {(!scanDesks || scanDesks.length === 0) && <li>No scan desks available</li>}
            </List>
        </div>
    </div>
}


export default ScanDesks;