export const selectNumberOfScansToBeSynced = (state) => state.scans.scans.filter(item => item && item.syncedAt === null).length;

export const selectScansToBySynced = (state) => state.scans.scans
    .filter(item => item && item.syncedAt === null)
    .map(({syncedAt, ...rest}) => rest);

export const selectBarcode = (state, barcode) => state.scans.barcodes.find(b => b.barcode === barcode);

export const selectScanProcessing = (state) => state.scans.current.isProcessing;

export const selectScanResult = (state) => state.scans.current.result;

export const selectIsScanned = (state, barcode) => state.scans.scans.find(s => s.barcode === barcode);