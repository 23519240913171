import React from 'react';
import PropTypes from 'prop-types';

const Spacer = ({height = 20}) =>
    <div style={{paddingTop: height}}/>

Spacer.propTypes = {
    height: PropTypes.number,
}

export default Spacer;
