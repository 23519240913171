import React from 'react';
import ScanDesks from "./ScanDesks";
import Scan from "./Scan";
import {useSelector} from "react-redux";
import {selectSelectedScanDesk} from "../selectors/scanDeskSelectors";


const NavigationScanStack = () => {

    const currentScanDesk = useSelector(selectSelectedScanDesk);
    if (!!currentScanDesk) {
        return <Scan/>
    }
    return <ScanDesks/>
}

export default NavigationScanStack;