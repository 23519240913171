export const selectScanDesks = state => state.scanDesks.items;

export const selectSelectedScanDesk = state => state.scanDesks.items
    .find(s => s.id === state.scanDesks.selected);

export const selectSelectedScanDeskId = state => state.scanDesks.selected;

export const selectSelectedScanDeskName = state => state.scanDesks.items
    .find(s => s.id === state.scanDesks.selected)?.name ?? '';

export const selectSelectedScanDeskFormId = state => state.scanDesks.items
    .find(s => s.id === state.scanDesks.selected)['form_id'];

export const selectScanDesksIsLoading = state => state.scanDesks.isLoading;