import {call, put, select, takeEvery} from 'redux-saga/effects';
import {REPORT_FETCH} from '../constants/actions';
import {
    selectAccessToken,
    selectFlowId,
    selectHost,
    selectNetworkIsConnected,
    selectProjectId
} from '../selectors/appSelectors';
import {Api} from '../api';
import {selectSelectedScanDeskFormId} from '../selectors/scanDeskSelectors';
import {reportFetched, reportFetchError, reportPreFetch} from '../actions/index';

function* fetchReport() {
    try {
        const isConnected = yield select(selectNetworkIsConnected);
        if (!isConnected) {
            return;
        }

        yield put(reportPreFetch());

        const host = yield select(selectHost);
        const token = yield select(selectAccessToken);
        const projectId = yield select(selectProjectId);
        const flowId = yield select(selectFlowId);
        const formId = yield select(selectSelectedScanDeskFormId);

        const response = yield call(Api.Stats, host, token, projectId, flowId, formId);
        const data = yield response.json();

        yield put(reportFetched(data));
    } catch (error) {
        yield put(reportFetchError());
    }

}

function* reportSagas() {
    yield takeEvery(REPORT_FETCH, fetchReport);
}

export default reportSagas;