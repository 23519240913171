import produce from 'immer';
import {
    INITIALIZE,
    PURGE,
    SCAN_DESK_SELECTED,
    SCAN_DESKS_FETCH_ERROR,
    SCAN_DESKS_FETCHED,
    SCAN_DESKS_PRE_FETCH,
} from '../constants/actions';

const initialState = {
    isLoading: false,
    syncedAt: null,
    items: [],
    selected: null,
};

const scanDesksReducer = (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case INITIALIZE:
                draft.isLoading = false;
                break;

            case SCAN_DESKS_PRE_FETCH:
                draft.isLoading = true;
                break;

            case SCAN_DESKS_FETCHED:
                draft.items = action.payload.scanDesks;
                draft.syncedAt = new Date().getTime();
                draft.isLoading = false;
                break;

            case SCAN_DESKS_FETCH_ERROR:
                draft.isLoading = false;
                break;

            case SCAN_DESK_SELECTED:
                draft.selected = action.payload.id;
                break;

            case PURGE:
                return initialState;
            default:
                break;
        }
    })
}

export default scanDesksReducer;
