import produce from 'immer';
import {
    INITIALIZE,
    AUTHENTICATE_ERROR,
    AUTHENTICATE_ERROR_RESET,
    AUTHENTICATED,
    LAST_SYNCED_AT,
    NETWORK_SET_CONNECTED,
    NETWORK_SET_DISCONNECTED,
    PRE_AUTHENTICATE,
    PURGE,
    SET_APP_STATE,
    SET_AUTHENTICATION_CONFIG,
    SET_NAME, SET_PAGE
} from '../constants/actions';
import {STATE_AUTHENTICATED, STATE_UNAUTHENTICATED} from '../constants/const';

const initialState = {
    name: '',
    state: STATE_UNAUTHENTICATED,
    authentication: {
        error: false,
        isProcessing: false,
    },
    oauth: {
        domain: '',
        clientId: '',
        clientSecret: '',
        flowId: null,
        projectId: null,
        token: null,
        tokenUrl: '',
    },
    sync: {
        lastSyncedAt: null,
    },
    network: {
        isConnected: true,
    },
    page: 'scan',
};

const appReducer = (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case INITIALIZE:
                draft.authentication.error = false;
                draft.authentication.isProcessing = false;
                break;

            case AUTHENTICATED:
                draft.state = STATE_AUTHENTICATED;
                draft.oauth.token = action.payload.token;
                draft.authentication.error = false;
                draft.authentication.isProcessing = false;
                break;

            case PRE_AUTHENTICATE:
                draft.authentication.isProcessing = true;
                break;

            case AUTHENTICATE_ERROR:
                draft.authentication.error = true;
                draft.authentication.isProcessing = false;
                break;

            case AUTHENTICATE_ERROR_RESET:
                draft.authentication.error = false;
                break;

            case SET_AUTHENTICATION_CONFIG:
                draft.oauth.domain = action.payload.domain;
                draft.oauth.clientId = action.payload.clientId;
                draft.oauth.clientSecret = action.payload.clientSecret;
                draft.oauth.tokenUrl = action.payload.tokenUrl;
                draft.oauth.flowId = action.payload.flowId;
                draft.oauth.projectId = action.payload.projectId;
                break;

            case SET_APP_STATE:
                draft.state = action.payload;
                break;

            case SET_NAME:
                draft.name = action.payload;
                break;

            case SET_PAGE:
                draft.page = action.payload;
                break;

            case NETWORK_SET_CONNECTED:
                draft.network.isConnected = true;
                break;

            case NETWORK_SET_DISCONNECTED:
                draft.network.isConnected = false;
                break;

            case LAST_SYNCED_AT:
                draft.lastSyncedAt = action.payload.timestamp;
                break;

            case PURGE:
                return {
                    ...initialState,
                    network: {
                        ...draft.network
                    }
                };
            default:
                break;
        }
    })
}

export default appReducer;
