import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {purge} from '../actions/index';
import {selectNumberOfScansToBeSynced} from "../selectors/scansSelectors";
import {selectName} from "../selectors/appSelectors";
import {Button} from "react-md";

const LogoutScreen = () => {
    const dispatch = useDispatch();
    const hasScannedData = useSelector(selectNumberOfScansToBeSynced);
    const name = useSelector(selectName);

    const logoutHandler = () => {
        dispatch(purge());
    };

    if (hasScannedData > 0) {
        return <>
                <p className="text-center font-bold text-xl">You cannot log out yet</p>
                <p className="text-center">
                    {hasScannedData === 1  && `${hasScannedData} QR code has not been synchronised with Halito!. Please connect to WiFi to synchronise`}
                    {hasScannedData > 1  && `${hasScannedData} QR codes have not been synchronised with Halito!. Please connect to WiFi to synchronise`}
                </p>
                <p>Please confirm if you want to erase the captured data? Once erased, the data CANNOT be retrieved.</p>
                <div style={{marginTop: 20}}>
                    <Button onClick={logoutHandler} theme="primary" themeType="contained">Delete not syncronised scans</Button>
                </div>
        </>
    }

    return <div className="h-full mt-4 flex flex-col gap-4">
            <p className="text-xl text-center">{`Bye ${name}!`}</p>
            <Button onClick={logoutHandler} theme="primary" themeType="contained">Log out</Button>
        </div>;
}

export default LogoutScreen;