import {decode as atob, encode} from 'base-64';
import CryptoJS from 'react-native-crypto-js';
import {barcodeSecret} from './config/config';

export const hideBottomNavigation = (navigation) => {
    navigation.getParent().setOptions({
        tabBarStyle: {
            display: 'none'
        }
    });

    return () => navigation.getParent().setOptions({
        tabBarStyle: undefined
    });
}

export const decryptBarcode = (barcode) => {
    try {
        let key = encode(barcodeSecret);

        let encrypted = barcode;
        encrypted = atob(encrypted);
        encrypted = JSON.parse(encrypted);

        const iv = CryptoJS.enc.Base64.parse(encrypted.iv);
        const value = encrypted.value;

        key = CryptoJS.enc.Base64.parse(key);
        let decrypted = CryptoJS.AES.decrypt(value, key, {
            iv: iv
        });

        return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (Error) {
        return false;
    }
}