import {all} from 'redux-saga/effects';
import scanDesksSagas from './scanDesksSagas';
import scanSagas from './scanSagas';
import syncSagas from './syncSagas';
import reportSagas from './reportSagas';
import authenticateSagas from './authenticationSagas';
import networkSagas from './networkSagas';

export default function* rootSaga() {
    yield all([
        authenticateSagas(),
        networkSagas(),
        reportSagas(),
        scanDesksSagas(),
        scanSagas(),
        syncSagas(),
    ]);
}