import {
    SCAN_DESK_SELECTED,
    SCAN_DESKS_FETCH,
    SCAN_DESKS_FETCH_ERROR,
    SCAN_DESKS_FETCHED,
    SCAN_DESKS_PRE_FETCH
} from '../constants/actions';

export const scanDesksFetch = () => ({
    type: SCAN_DESKS_FETCH,
});

export const scanDesksPreFetch = () => ({
    type: SCAN_DESKS_PRE_FETCH,
});

export const scanDesksFetched = (scanDesks) => ({
    type: SCAN_DESKS_FETCHED,
    payload: {
        scanDesks: scanDesks,
    }
});

export const scanDeskFetchError = () => ({
    type: SCAN_DESKS_FETCH_ERROR,
});

export const scanDeskSelected = (id) => ({
    type: SCAN_DESK_SELECTED,
    payload: {
        id,
    }
});