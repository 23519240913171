// noinspection ExceptionCaughtLocallyJS

import {call, put, select, takeEvery} from 'redux-saga/effects';
import {AUTHENTICATE} from '../constants/actions';
import {decryptBarcode} from '../helpers';
import {Api} from '../api';
import {selectAuthenticationIsProcessing} from '../selectors/appSelectors';
import {
    authenticated,
    authenticateError,
    preAuthenticate,
    scanDesksFetch,
    setAuthenticationConfig,
    sync
} from '../actions/index';

function* authenticate(action) {
    try {
        const isProcessing = yield select(selectAuthenticationIsProcessing);
        if (isProcessing) {
            return;
        }

        yield put(preAuthenticate());

        const barcode = decryptBarcode(action.payload.token);

        if (barcode === false) {
            throw new Error('invalid barcode');
        }

        const {
            host = null,
            client_id = null,
            client_secret = null,
            flow_id = null,
            project_id = null,
            token_url = null
        } = JSON.parse(barcode);

        if (token_url && client_id && client_secret) {
            yield put(setAuthenticationConfig(host, token_url, client_id, client_secret, project_id, flow_id));
            const response = yield call(Api.Authenticate, token_url, client_id, client_secret);
            const data = yield response.json();

            if (!data.hasOwnProperty('access_token')) {
                throw new Error('invalid credentials');
            }

            yield put(authenticated(data));
            yield put(scanDesksFetch());
            yield put(sync());
        } else {
            throw new Error('invalid barcode');
        }
    } catch (exception) {
        console.log(`Auth exception: ${exception.message}`);
        yield put(authenticateError(exception.message));
    }
}

function* authenticateSagas() {
    yield takeEvery(AUTHENTICATE, authenticate);
}

export default authenticateSagas;