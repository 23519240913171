import {call, put, select, takeLeading} from 'redux-saga/effects';
import {SYNC} from '../constants/actions';
import {
    selectAccessToken,
    selectHost,
    selectIsAuthenticated,
    selectNetworkIsConnected, selectLastSyncedAt,
    selectProjectId
} from '../selectors/appSelectors';
import {selectScansToBySynced} from '../selectors/scansSelectors';
import {Api} from '../api';
import {lastSyncedAt, scansSynced, syncBarcodes} from '../actions/index';

function* sync() {
    const isAuthenticated = yield select(selectIsAuthenticated);
    if (!isAuthenticated) {
        console.log('Not authenticated, not syncing');
        return;
    }

    const isConnected = yield select(selectNetworkIsConnected);
    if (!isConnected) {
        console.log('Not syncing: not connected to the internet');
        return;
    }

    const token = yield select(selectAccessToken);
    const projectId = yield select(selectProjectId);
    const host = yield select(selectHost)
    const syncedAt = yield select(selectLastSyncedAt)
    const toBeSynced = yield(select(selectScansToBySynced));

    try {
        const result = yield call(Api.Sync, host, token, projectId, toBeSynced, syncedAt);
        const data = yield result.json();

        yield put(lastSyncedAt(data.syncedAt));
        yield put(syncBarcodes(data.barcodes));
        yield put(scansSynced(toBeSynced));
    } catch (error) {
        console.log(`Could not sync: ${error.message}`);
    }
}

function* syncSagas() {
    yield takeLeading(SYNC, sync);
}

export default syncSagas;