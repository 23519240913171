import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectScanProcessing, selectScanResult} from '../selectors/scansSelectors';
import {barcodeScan, scanDeskSelected} from '../actions/index';
import ScanSyncStatus from "./ScanSyncStatus";
import ScanResponse from "./ScanResponse";
import { Scanner } from '@yudiel/react-qr-scanner';
import {ArrowBackFontIcon, Button} from "react-md";
import {selectSelectedScanDesk} from "../selectors/scanDeskSelectors";


const Scan = () => {
    const dispatch = useDispatch();
    const isProcessing = useSelector(selectScanProcessing);
    const result = useSelector(selectScanResult);
    const scanDesk = useSelector(selectSelectedScanDesk);
    const [isPaused, setIsPaused] = useState(true);

    const handleBarcode = (barcodes) => {
        dispatch(barcodeScan(barcodes[0].rawValue));
    }

    useEffect(() => {
        setIsPaused(isProcessing || result !== null);
    }, [setIsPaused, isProcessing, result]);

    const resetScandesk = () => {
        dispatch(scanDeskSelected(null));
    }

    return (
        <>
            <ScanSyncStatus></ScanSyncStatus>
            <div className="flex gap-3 items-center">
                <Button onClick={resetScandesk} theme="primary" buttonType="icon" themeType="outline">
                    <ArrowBackFontIcon/>
                </Button>
                <div className="font-bolder text-2xl text-primary">
                    {scanDesk.name}
                </div>
            </div>
            <div className="w-4/5 max-w-[250px] mx-auto">
                <Scanner  allowMultiple={true} paused={isPaused} onScan={handleBarcode}/>
            </div>
            <p className="text-center italic">Scan a QR code</p>
            <div>
                {result === true && <ScanResponse type={'success'}/>}
                {result === false && <ScanResponse type={'failure'}/>}
            </div>
        </>
    )
};

export default Scan;