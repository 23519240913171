import {REPORT_FETCH, REPORT_FETCH_ERROR, REPORT_FETCHED, REPORT_PRE_FETCH} from '../constants/actions';

export const reportFetch = () => ({
    type: REPORT_FETCH,
});

export const reportPreFetch = () => ({
    type: REPORT_PRE_FETCH,
});

export const reportFetched = (data) => ({
    type: REPORT_FETCHED,
    payload: {
        data,
    }
});

export const reportFetchError = () => ({
    type: REPORT_FETCH_ERROR,
});