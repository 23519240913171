import React from 'react';
import PropTypes from 'prop-types';
import HalitoScreen from './HalitoScreen';

const ConnectionRequired = ({message}) => <HalitoScreen>
    <div>
        <div>Wifi-icon</div>
        <p style={{textAlign: 'center'}}>{message}</p>
    </div>
</HalitoScreen>;

ConnectionRequired.propTypes = {
    message: PropTypes.string.isRequired,
};

export default ConnectionRequired;