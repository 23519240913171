
const error = 'rgb(37,122,197)';
const primary = 'rgb(30,71,31)';
const secondary = 'rgb(193,197,37)';
const tertiary = 'rgb(110,110,110)';

const HalitoTheme = {
    colors: {
        error: error,
        primary: primary,
        secondary: secondary,
        tertiary: tertiary,
    }
}


export {
    HalitoTheme,
}