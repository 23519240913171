import {INITIALIZE, PURGE, SET_PAGE} from '../constants/actions';

export const initialize = () => ({
    type: INITIALIZE,
});

export const purge = () => ({
    type: PURGE,
});

export const setPage = (page) => ({
    type: SET_PAGE,
    payload: page
})