import React from 'react';
import Logout from './Logout';
import {useDispatch, useSelector} from "react-redux";
import {setPage} from "../actions/app";
import {selectPage} from "../selectors/appSelectors";
import Report from './Report'
import NavigationScanStack from './NavigationScanStack';
import {
    ConfirmationNumberFontIcon,
    ExitToAppFontIcon,
    PieChartOutlinedFontIcon
} from "react-md";
import logo from "../HalitoLogoTransparent.png";
import styles from "../styles/styles";

const NavigationBottomStack = () => {

    const dispatch = useDispatch();

    const setTab = (page) => dispatch(setPage(page))
    const openTab = useSelector(selectPage);

    return <>
        <div className='w-5/12'>
            <img src={logo} alt="Halito Scanapp" style={styles['home-logo']}/>
        </div>
        {openTab === 'scan' && <NavigationScanStack/>}
        {openTab === 'report' && <Report/>}
        {openTab === 'logout' && <Logout/>}
        <div className="w-full fixed left-0 bottom-0 flex justify-stretch items-stretch content-stretch gap-1">
            <div className="flex flex-col gap-1 flex-grow text-center text-white bg-primary p-3 rounded-tl-xl" onClick={() => setTab('scan')}>
                <ConfirmationNumberFontIcon style={{color: 'white'}}></ConfirmationNumberFontIcon>
                <div>Scan</div>
            </div>
            <div className="flex flex-col gap-1 flex-grow text-center text-white bg-primary p-3" onClick={() => setTab('report')}>
                <PieChartOutlinedFontIcon style={{color: 'white'}}></PieChartOutlinedFontIcon>
                <div>Report</div>
            </div>
            <div className="flex flex-col gap-1 flex-grow text-center text-white bg-primary p-3 rounded-tr-xl" onClick={() => setTab('logout')}>
                <ExitToAppFontIcon style={{color: 'white'}}></ExitToAppFontIcon>
                <div>Logout</div>
            </div>
        </div>
    </>;
}

export default NavigationBottomStack;