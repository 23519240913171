import {LAST_SYNCED_AT, SCANS_SYNCED, SYNC, SYNC_BARCODES} from '../constants/actions';

export const sync = () => ({
    type: SYNC,
});

export const scansSynced = (scans) => ({
    type: SCANS_SYNCED,
    payload: {
        scans,
    }
});

export const lastSyncedAt = (timestamp) => ({
    type: LAST_SYNCED_AT,
    payload: {
        timestamp,
    }
});

export const syncBarcodes = (barcodes) => ({
    type: SYNC_BARCODES,
    payload: {
        barcodes,
    }
});