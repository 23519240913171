import './index.scss'
import './App.css';
import {persistor, store} from './store';
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import Sync from "./components/Sync";
import Home from "./components/Home";


function App() {
  return (
      <Provider store={store}>
          <PersistGate persistor={persistor}>
              <Sync>
                  <Home/>
              </Sync>
          </PersistGate>
      </Provider>
  );
}

export default App;
