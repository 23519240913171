import React from 'react';
import PropTypes from 'prop-types';
import Spacer from './Spacer';

const ScanDesk = ({id, name, onSelect}) => {
    return <div>
        <button onClick={() => onSelect(id)}>{name}</button>
        <Spacer/>
    </div>
}

ScanDesk.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
};

export default ScanDesk;