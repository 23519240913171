import React from 'react';
import {useDispatch} from 'react-redux';
import {barcodeResetResult} from '../actions/index';
import {CheckCircleFontIcon, ErrorOutlineFontIcon} from "react-md";

const ScanResponse = ({type}) => {
    const dispatch = useDispatch();

    const text = type === 'success' ? 'Success' : 'Failed';
    const iconStyle = {color: 'white', fontSize: '10rem'};
    const color = type === 'success' ? 'bg-secondary' : 'bg-error';

    const handleClose = () => dispatch(barcodeResetResult());

    return <div className={"flex fixed z-10 top-0 left-0 w-screen h-screen " + color} onClick={handleClose}>
        <div className="m-auto text-center text-7xl flex flex-col gap-3">
            <p className="text-white font-bold">{text}</p>
            <div>
                {type === 'success' ? <CheckCircleFontIcon style={iconStyle}/> : <ErrorOutlineFontIcon style={iconStyle}/>}
            </div>
        </div>
    </div>
};

export default ScanResponse;