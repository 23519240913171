import {
    AUTHENTICATE,
    AUTHENTICATE_ERROR,
    AUTHENTICATE_ERROR_RESET,
    AUTHENTICATED,
    PRE_AUTHENTICATE,
    SET_APP_STATE,
    SET_AUTHENTICATION_CONFIG,
    SET_NAME
} from '../constants/actions';

export const authenticate = (token) => ({
    type: AUTHENTICATE,
    payload: {
        token,
    }
});

export const authenticateError = (message) => ({
    type: AUTHENTICATE_ERROR,
    payload: {
        message,
    }
});

export const authenticateErrorReset = () => ({
    type: AUTHENTICATE_ERROR_RESET,
});

export const authenticated = (token) => ({
    type: AUTHENTICATED,
    payload: {
        token,
    },
});

export const preAuthenticate = () => ({
    type: PRE_AUTHENTICATE,
});

export const setAuthenticationConfig = (domain, tokenUrl, clientId, clientSecret, projectId, flowId) => ({
    type: SET_AUTHENTICATION_CONFIG,
    payload: {
        domain,
        tokenUrl,
        clientId,
        clientSecret,
        projectId,
        flowId,
    }
});

export const setName = (name) => ({
    type: SET_NAME,
    payload: name,
});

export const setAppState = (state) => ({
    type: SET_APP_STATE,
    payload: state,
});