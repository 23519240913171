import produce from 'immer';
import {
    INITIALIZE,
    BARCODE_PRE_SCAN,
    BARCODE_RESET_RESULT,
    BARCODE_SCAN_NOT_FOUND,
    BARCODE_SCANNED,
    PURGE,
    SCANS_SYNCED,
    SYNC_BARCODES
} from '../constants/actions';

const initialState = {
    barcodes: [],
    scans: [],
    current: {
        isProcessing: false,
        result: null
    },
};

const scanReducer = (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case INITIALIZE:
                draft.current.isProcessing = false;
                break;

            case BARCODE_PRE_SCAN:
                draft.current.isProcessing = true;
                break;

            case BARCODE_SCANNED:
                draft.current.result = true;
                draft.scans.push(action.payload.barcode);
                break;

            case BARCODE_SCAN_NOT_FOUND:
                draft.current.result = false;
                break;

            case BARCODE_RESET_RESULT:
                draft.current.isProcessing = false;
                draft.current.result = null;
                break;

            case SCANS_SYNCED:
                action.payload.scans.forEach(
                    (scan) => {
                        draft.scans = draft.scans.filter(item => item.barcode !== scan.barcode);
                    }
                )
                break;

            case SYNC_BARCODES:
                action.payload.barcodes.forEach(
                    (barcode) => {
                        if (!draft.barcodes) {
                            draft.barcodes = [];
                        }

                        draft.barcodes = draft.barcodes.filter((b) => b.barcode !== barcode.barcode);
                        draft.barcodes.push(barcode);
                    }
                )
                break;

            case PURGE:
                return initialState;
            default:
                break;
        }
    });
}

export default scanReducer;