import {call, put, select, takeEvery} from 'redux-saga/effects';
import {BARCODE_SCAN} from '../constants/actions';
import {Api} from '../api';
import {selectAccessToken, selectHost, selectNetworkIsConnected, selectProjectId} from '../selectors/appSelectors';
import {selectBarcode, selectIsScanned} from '../selectors/scansSelectors';
import {selectSelectedScanDesk, selectSelectedScanDeskId} from '../selectors/scanDeskSelectors';
import {barcodePreScan, barcodeScanned, barcodeScanNotFound} from '../actions/index';

function* barcodeScan(action) {
    yield put(barcodePreScan());

    const domain = yield select(selectHost);
    const projectId = yield select(selectProjectId);
    const scanDesk = yield select(selectSelectedScanDesk);
    const scanDeskId = yield select(selectSelectedScanDeskId);
    const token = yield select(selectAccessToken);
    const isScanned = yield select(selectIsScanned, action.payload.barcode);
    const isConnected = yield select(selectNetworkIsConnected);

    if (isScanned) {
        yield put(barcodeScanNotFound());
        yield window.navigator.vibrate(1000);
        return;
    }

    let scan = null;
    let barcode = yield select(selectBarcode, action.payload.barcode);
    let noAccess = false;

    if (barcode) {
        const barcodeScanDesk = barcode['scandesks'].find(s => s.id === scanDeskId);

        if (!barcodeScanDesk || barcodeScanDesk.access === false) {
            console.log('no access! reset barcode');
            noAccess = true;
            barcode = null;
        } else {
            scan = {
                scandesk_id: scanDeskId,
                barcode: action.payload.barcode,
                timestamp: new Date().getTime(),
                syncedAt: null,
            }
        }
    }

    if (!barcode && isConnected && noAccess === false) {
        const {
            flow_id: flowId,
            form_id: formId
        } = scanDesk;
        try {
            const response = yield call(Api.Scan, domain, token, projectId, flowId, formId, scanDeskId, action.payload.barcode);
            const data = yield response.json();

            if (data.hasOwnProperty('response')) {
                scan = {
                    scandesk_id: scanDeskId,
                    barcode: action.payload.barcode,
                    timestamp: new Date().getTime(),
                    syncedAt: null,
                }
            }
        } catch (error) {
            console.log(`Could not register online: ${error.message}`);
        }
    }

    if (!scan) {
        yield put(barcodeScanNotFound());
        yield window.navigator.vibrate(1000);

    } else {
        yield put(barcodeScanned(scan));
        yield window.navigator.vibrate(100);
    }
}

function* scanSagas() {
    yield takeEvery(BARCODE_SCAN, barcodeScan);
}

export default scanSagas;