import {put, takeLeading} from 'redux-saga/effects';
import {NETWORK_SET_CONNECTED} from '../constants/actions';
import {sync} from '../actions/index';

function* connected() {
    yield put(sync());
}

function* networkSagas() {
    yield takeLeading(NETWORK_SET_CONNECTED, connected);
}

export default networkSagas;