import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectReportData, selectReportIsLoading} from '../selectors/reportSelectors';
import ConnectionRequired from './ConnectionRequired';
import Loading from './Loading';
import {selectNetworkIsConnected} from '../selectors/appSelectors';
import {reportFetch} from '../actions/index';
import {selectSelectedScanDeskId} from "../selectors/scanDeskSelectors";
import {HalitoTheme} from "../styles/theme";
import scanDesk from "./ScanDesk";

const Report = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectReportIsLoading);
    const isConnected = useSelector(selectNetworkIsConnected);
    const hasSelectedScanDesk = useSelector(selectSelectedScanDeskId);
    const data = useSelector(selectReportData);
    const theme = HalitoTheme

    useEffect(() => {
        dispatch(reportFetch());
    }, [hasSelectedScanDesk, dispatch]);

    if (!hasSelectedScanDesk) {
        return <div>
            <p>First select your position</p>
        </div>
    }

    if (!isConnected) {
        return <ConnectionRequired message={'Halito! ScanApp requires internet connectivity to show reporting'}/>
    }

    if (isLoading) {
        return <Loading/>
    }

    const radius = 500;
    const strokeWidth = radius * .2;
    const innerRadius = radius - (strokeWidth / 2);
    const circumference = innerRadius * 2 * Math.PI;
    const arc = circumference * (270 / 360);
    const dashArray = `${arc} ${circumference}`;
    const transform = `rotate(135, ${radius}, ${radius})`;
    const percent = Math.round(data.scanned / (data.total ?? 0) * 100 * 100) / 100;
    const percentNormalized = Math.min(Math.max(percent, 0), 100);
    const offset = arc - (percentNormalized / 100) * arc;

    return <div className="mt-4">
        <h1 className="font-bold text-xl text-primary text-center mb-5">{scanDesk.name}</h1>
        <div className="w-4/5 m-auto">
            <svg height={'100%'} width={'100%'} viewBox={'0 0 1000 1000'}>
                <circle
                    cx={radius}
                    cy={radius}
                    fill={'transparent'}
                    r={innerRadius}
                    stroke={theme.colors.primary}
                    strokeDasharray={dashArray}
                    strokeLinecap={'round'}
                    strokeWidth={strokeWidth}
                    transform={transform}
                />
                {data.scanned > 0 &&
                    <circle
                        cx={radius}
                        cy={radius}
                        fill={'transparent'}
                        r={innerRadius}
                        stroke={theme.colors.secondary}
                        strokeDasharray={dashArray}
                        strokeDashoffset={offset}
                        strokeWidth={strokeWidth}
                        strokeLinecap={'round'}
                        transform={transform}
                    />}
                <text fontSize={160}
                      fontWeight={'bold'}
                      fill={theme.colors.primary}
                      textAnchor={'middle'}
                      x={'50%'}
                      y={'55%'}
                >{`${percent}%`}</text>
                <text
                    fontSize={60}
                    fill={theme.colors.tertiary}
                    textAnchor={'middle'}
                    x={'50%'}
                    y={'35%'}
                >
                    {`${data.scanned} / ${data.total}`}
                </text>
            </svg>
        </div>
    </div>;
}

export default Report;