import {
    BARCODE_PRE_SCAN,
    BARCODE_RESET_RESULT,
    BARCODE_SCAN,
    BARCODE_SCAN_NOT_FOUND,
    BARCODE_SCANNED
} from '../constants/actions';

export const barcodeScan = (barcode) => ({
    type: BARCODE_SCAN,
    payload: {
        barcode,
    }
});

export const barcodePreScan = () => ({
    type: BARCODE_PRE_SCAN,
});

export const barcodeScanned = (barcode) => ({
    type: BARCODE_SCANNED,
    payload: {
        barcode,
    }
});

export const barcodeResetResult = () => ({
    type: BARCODE_RESET_RESULT,
});

export const barcodeScanNotFound = () => ({
    type: BARCODE_SCAN_NOT_FOUND,
});