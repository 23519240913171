import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {STATE_IDENTIFIED} from '../constants/const';
import {selectName} from '../selectors/appSelectors';
import {setAppState, setName} from '../actions/index';
import {Button, TextField} from "react-md";
import AuthenticateTop from "./AuthenticateTop";

const Identify = () => {
    const dispatch = useDispatch();
    const name = useSelector(selectName);

    const canSubmit = () => name !== '';

    const onNameChange = (value) => dispatch(setName(value.target.value));
    const onStart = () => {
        if (name.trim() !== '') {
            dispatch(setAppState(STATE_IDENTIFIED));
        }
    }

    return <>
        <div>
            <AuthenticateTop/>
        </div>
        <TextField id="user-name"
                   onChange={onNameChange}
                   value={name}
                   label="Please enter your name"
                   placeholder={'Name'}
        />
        <Button
            onClick={onStart}
            disabled={!canSubmit()}
            theme="primary"
            themeType="contained"
        >Start</Button>
    </>;
};

export default Identify;