import React from 'react';
import {useSelector} from 'react-redux';
import {selectNumberOfScansToBeSynced} from '../selectors/scansSelectors';
import {selectNetworkIsConnected} from '../selectors/appSelectors';
import {SignalWifiOffFontIcon, WifiFontIcon} from "react-md";

const ScanSyncStatus = () => {
    const numberOfScansToBeSynced = useSelector(selectNumberOfScansToBeSynced);
    const isConnected = useSelector(selectNetworkIsConnected);

    return <div className="w-full flex gap-3 text-sm justify-end text-gray-500">
        <div>{`${numberOfScansToBeSynced} scans to be synced`}</div>
        <div>{isConnected ? <WifiFontIcon/> : <SignalWifiOffFontIcon/>}</div>
    </div>;
}

export default ScanSyncStatus;