import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectIsIdentified, selectIsNotAuthenticated} from '../selectors/appSelectors';
import Identify from './Identify';
import {initialize} from '../actions/index';
import Authenticate from "./Authenticate";
import NavigationBottomStack from "./NavigationBottomStack";

const Home = () => {
    const dispatch = useDispatch();
    const isIdentified = useSelector(selectIsIdentified);
    const isNotAuthenticated = useSelector(selectIsNotAuthenticated);

    useEffect(() => {
        dispatch(initialize());
    }, [dispatch]);

    return <div className="m-auto container h-screen max-w-screen-sm py-6 px-3 bg-zinc-100 flex flex-col gap-5">
        {isNotAuthenticated && <Authenticate/>}
        {!isNotAuthenticated && !isIdentified && <Identify/>}
        {isIdentified && <NavigationBottomStack/>}
    </div>
}

export default Home;