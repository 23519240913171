import React from 'react';
import logo from '../HalitoLogoTransparent.png';
import styles from '../styles/styles';

const AuthenticateTop = () => (
    <div className="flex justify-center">
        <div className="w-7/12">
            <img src={logo} alt="Halito Scanapp" style={styles['home-logo']}/>
            <p className="text-center text-2xl font-bold text-primary">Scan app</p>
        </div>
    </div>
);

export default AuthenticateTop;
