// app actions
export const INITIALIZE = 'APP_INITIALIZE';
export const PURGE = 'PURGE';
export const SET_PAGE = 'SET_PAGE';

// authentication actions
export const AUTHENTICATE = 'AUTHENTICATE';
export const AUTHENTICATE_ERROR = 'AUTHENTICATE_ERROR';
export const AUTHENTICATE_ERROR_RESET = 'AUTHENTICATE_ERROR_RESET';
export const AUTHENTICATED = 'AUTHENTICATED';
export const PRE_AUTHENTICATE = 'PRE_AUTHENTICATE';
export const SET_AUTHENTICATION_CONFIG = 'SET_AUTHENTICATION_CONFIG';
export const SET_APP_STATE = 'SET_APP_STATE';
export const SET_NAME = 'SET_NAME';

// network actions
export const NETWORK_SET_CONNECTED = 'NETWORK_SET_CONNECTED';
export const NETWORK_SET_DISCONNECTED = 'NETWORK_SET_DISCONNECTED';

// report actions
export const REPORT_FETCH = 'REPORT_FETCH';
export const REPORT_PRE_FETCH = 'REPORT_PRE_FETCH';
export const REPORT_FETCHED = 'REPORT_FETCHED';
export const REPORT_FETCH_ERROR = 'REPORT_FETCH_ERROR';

// scan actions
export const BARCODE_SCAN = 'BARCODE_SCAN';
export const BARCODE_PRE_SCAN = 'BARCODE_PRE_SCAN';
export const BARCODE_SCANNED = 'BARCODE_SCANNED';
export const BARCODE_SCAN_NOT_FOUND = 'BARCODE_SCAN_NOT_FOUND';
export const BARCODE_RESET_RESULT = 'BARCODE_RESET_RESULT';

// scanDesk actions
export const SCAN_DESKS_FETCH = 'SCAN_DESKS_FETCH';
export const SCAN_DESKS_PRE_FETCH = 'SCAN_DESKS_PRE_FETCH';
export const SCAN_DESKS_FETCHED = 'SCAN_DESKS_FETCHED';
export const SCAN_DESKS_FETCH_ERROR = 'SCAN_DESKS_FETCH_ERROR';
export const SCAN_DESK_SELECTED = 'SCAN_DESK_SELECTED';

// sync actions
export const SYNC = 'SYNC';
export const SCANS_SYNCED = 'SCANS_SYNCED';
export const LAST_SYNCED_AT = 'LAST_SYNCED_AT';
export const SYNC_BARCODES = 'SYNC_BARCODES';