import produce from 'immer';
import {INITIALIZE, PURGE, REPORT_FETCH_ERROR, REPORT_FETCHED, REPORT_PRE_FETCH} from '../constants/actions';

const initialState = {
    isLoading: false,
    data: {
        scanned: 0,
        total: 0,
    }
}

const reportReducer = (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case INITIALIZE:
                draft.isLoading = false;
                break;

            case REPORT_PRE_FETCH:
                draft.isLoading = true;
                break;

            case REPORT_FETCHED:
                draft.data = action.payload.data;
                draft.isLoading = false;
                break;

            case REPORT_FETCH_ERROR:
                draft.isLoading = false;
                break;

            case PURGE:
                return initialState;
            default:
                break;
        }
    })
}

export default reportReducer;