import React from 'react';
import SupportButton from "./SupportButton";

const AuthenticateBottom = () => (
    <>
        <p className="text-center italic">Scan your authorization QR code to continue</p>
        <p className="text-center"><SupportButton/></p>
    </>
);

export default AuthenticateBottom;