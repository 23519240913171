import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    selectAuthenticationError,
    selectAuthenticationIsProcessing,
    selectNetworkIsConnected,
} from '../selectors/appSelectors';
import {authenticate, authenticateErrorReset} from '../actions/index';
import ConnectionRequired from "./ConnectionRequired";
import AuthenticateBottom from "./AuthenticateBottom";
import AuthenticateTop from "./AuthenticateTop";
import {Scanner} from '@yudiel/react-qr-scanner';
import Loading from "./Loading";

const Authenticate = () => {
    const dispatch = useDispatch();
    const isConnected = useSelector(selectNetworkIsConnected);
    const error = useSelector(selectAuthenticationError);
    const processing = useSelector(selectAuthenticationIsProcessing);

    const scanCode = (scanResult) => {
        dispatch(authenticate(scanResult[0].rawValue));
    }

    // reset error scans
    useEffect(() => {
        if (error === true) {
            setTimeout(() => dispatch(authenticateErrorReset()), 1000);
        }
    }, [error, dispatch]);

    // if the is no internet connectivity
    if (!isConnected) {
        return <ConnectionRequired message={'Halito! ScanApp requires internet connectivity to authenticate'}/>
    }

    return <>
        <AuthenticateTop/>
        <div className="w-4/5 max-w-[250px] mx-auto">
            <Scanner allowMultiple={true} onScan={scanCode}></Scanner>
        </div>
        {processing && <Loading/>}
        <AuthenticateBottom/>
    </>;
};

export default Authenticate;