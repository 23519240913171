import React from 'react';
import {FontIcon, Link} from "react-md";

const SupportButton = () => {
    const url = 'https://support.halito.com/?s=scan-app';
    return <Link href={url} target="_blank">
        <FontIcon>help</FontIcon>
    </Link>
};

export default SupportButton;