import {call, put, select, takeEvery} from 'redux-saga/effects';
import {SCAN_DESKS_FETCH} from '../constants/actions';
import {Api} from '../api';
import {
    selectAccessToken,
    selectFlowId,
    selectHost,
    selectNetworkIsConnected,
    selectProjectId
} from '../selectors/appSelectors';
import {scanDeskFetchError, scanDesksFetched, scanDesksPreFetch} from '../actions/index';

function* fetchScanDesks() {
    try {
        const isConnected = yield select(selectNetworkIsConnected);
        if (!isConnected) {
            return;
        }

        yield put(scanDesksPreFetch());

        const domain = yield select(selectHost);
        const token = yield select(selectAccessToken);
        const projectId = yield select(selectProjectId);
        const flowId = yield select(selectFlowId);

        try {
            const response = yield call(Api.GetScanDesks, domain, token, projectId, flowId);
            const scanDesks = yield response.json()
                .then((data) => data.data);

            yield put(scanDesksFetched(scanDesks));
        } catch (error) {
            yield put(scanDeskFetchError());
            console.log(`Could not fetch scanDesks: ${error.message}`);
        }

    } catch (error) {
        yield put(scanDeskFetchError());
    }
}

function* scanDeskSagas() {
    yield takeEvery(SCAN_DESKS_FETCH, fetchScanDesks);
}

export default scanDeskSagas;